<template>
  <div>
    Площадки и договоры
  </div>
</template>

<script>
export default {
  name: 'PlacesAndContractsView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
